:root {
    --bg-primary: #1E1E1E;
    --color-primary: #61DAFB;
    --color-secondary: #BB86FC;
    --color-accent: #03DAC6;
    --text-primary: #FFFFFF;
    --text-secondary: #B3B3B3;
    --color-error: #CF6679;
  }
  
  body {
    background-color: var(--bg-primary);
    color: var(--text-primary);
  }
  
  .navbar {
    background-color: var(--bg-primary) !important;
    border-bottom: 1px solid var(--color-accent);
  }
  
  .navbar-brand, .nav-link {
    color: var(--color-primary) !important;
  }
  
  .card {
    background-color: #2C2C2C;
    border-color: var(--color-accent);
  }
  
  .card-title {
    color: var(--color-primary);
  }
  
  .card-subtitle {
    color: var(--color-secondary);
  }
  
  .btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--bg-primary);
  }
  
  .btn-secondary {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    color: var(--bg-primary);
  }
  
  .badge-primary {
    background-color: var(--color-primary) !important;
    color: var(--bg-primary) !important;
  }
  
  .badge-info {
    background-color: var(--color-secondary) !important;
    color: var(--bg-primary) !important;
  }
  
  .badge-secondary {
    background-color: var(--color-accent) !important;
    color: var(--bg-primary) !important;
  }