.quote-item {
  color: white;
  overflow: visible;
  position: relative;
  z-index: 1;
  text-align: left;
}

.loading-indicator {
  text-align: center;
  padding: 20px 0;
  font-style: italic;
  color: #6c757d;
}

.quote-content {
  background-color: white;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.quote-content.expanded {
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.quote-thumbnail {
  object-fit: cover;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}

.frowny-face {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.quote-image {
  max-height: 200px;
  width: auto;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 15px;
}

.quote-text {
  font-size: 1rem;
  font-style: italic;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.author {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: white !important;
  font-weight: bold;
}

.field-label {
  font-weight: bold;
  color: var(--color-accent);
}

.field-label.italic {
  font-style: italic;
}

.field-label-small {
  font-size: 0.8rem;
  color: var(--color-accent);
  font-style: italic;
}

.analysis-header {
  font-size: 0.9rem;
  color: var(--color-primary);
}

.analysis-badge {
  font-size: 0.8rem;
  cursor: pointer;
}

.fade-in {
  animation: fadeIn 0.3s;
}

.normal-text {
  color: white;
}

.analysis-content {
  color: white;
  padding-top: 10px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}